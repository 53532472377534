import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Container from 'components/Container';
import FormElement from 'components/FormElement';
import FormField from 'components/FormField';
import * as CONSTANTS from 'core/constants/config';
import useHeader from 'core/hooks/use-header';
import * as OptionTypes from 'core/models/options.model';
import * as UserTypes from 'core/models/user.model';
import { UserData } from 'core/models/user.model';
import { AppDispatch } from 'core/store/store';
import { UserSelectors } from 'core/store/user/user.selectors';
import { UserThunks } from 'core/store/user/user.thunks';
import styles from './UserQuestionnaire.module.css';

type Inputs = {
  age: number;
  gender: UserTypes.UserGender;
  professionalProject: string | null;
  studiesLevel: UserTypes.UserStudiesLevel;
  familySituation: UserTypes.UserFamilySituation;
  studentResidenceArea: UserTypes.UserResidenceArea;
  tutorResidenceArea: UserTypes.UserResidenceArea;
  studentParentCategory: UserTypes.UserStudentParentCategory;
  artisanCSP: UserTypes.UserArtisanCSP;
  intermediateCSP: UserTypes.UserIntermediateCSP;
  employeeCSP: UserTypes.UserEmployeeCSP;
  workerCSP: UserTypes.UserWorkerCSP;
  inactiveCSP: UserTypes.UserInactiveCSP;
};

const UserQuestionnaire: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setBackPath } = useHeader();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(UserSelectors.data);

  const { formState, handleSubmit, register } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      age: user?.age,
      gender: user?.gender,
      studiesLevel: user?.studiesLevel,
      familySituation: user?.familySituation,
      studentResidenceArea: user?.studentResidenceArea,
      tutorResidenceArea: user?.tutorResidenceArea,
      studentParentCategory: user?.studentParentCategory,
      artisanCSP: user?.artisanCSP,
      intermediateCSP: user?.intermediateCSP,
      employeeCSP: user?.employeeCSP,
      workerCSP: user?.workerCSP,
      inactiveCSP: user?.inactiveCSP,
      professionalProject: user?.professionalProject,
    },
  });
  const { isSubmitting, errors, dirtyFields } = formState;
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);

  const studentResidenceAreaOptions = t('user-form.fields.student-residence-area.options', {
    returnObjects: true,
  }) as OptionTypes.ResidenceAreaOption[];
  const tutorResidenceAreaOptions = t('user-form.fields.tutor-residence-area.options', {
    returnObjects: true,
  }) as OptionTypes.ResidenceAreaOption[];
  const studentParentCategoryOptions = t('user-form.fields.student-parent-category.options', {
    returnObjects: true,
  }) as OptionTypes.StudentParentCategoryOption[];
  const artisanCSPOptions = t('user-form.fields.artisan-csp.options', {
    returnObjects: true,
  }) as OptionTypes.ArtisanCSPOption[];
  const intermediateCSPOptions = t('user-form.fields.intermediate-csp.options', {
    returnObjects: true,
  }) as OptionTypes.IntermediateCSPOption[];
  const employeeCSPOptions = t('user-form.fields.employee-csp.options', {
    returnObjects: true,
  }) as OptionTypes.EmployeeCSPOption[];
  const workerCSPOptions = t('user-form.fields.worker-csp.options', {
    returnObjects: true,
  }) as OptionTypes.WorkerCSPOption[];
  const inactiveCSPOptions = t('user-form.fields.inactive-csp.options', {
    returnObjects: true,
  }) as OptionTypes.InactiveCSPOption[];
  const genderOptions = t('user-form.fields.gender.options', { returnObjects: true }) as OptionTypes.GenderOption[];
  const studiesLevelOptions = t('user-form.fields.studies-level.options', {
    returnObjects: true,
  }) as OptionTypes.StudiesLevelOption[];
  const situationOptions = t('user-form.fields.family-situation.options', {
    returnObjects: true,
  }) as OptionTypes.FamilySituationOption[];

  const onSubmit = async (inputs: Inputs) => {
    try {
      const data: UserData = {
        ...inputs,
        studentResidenceArea: inputs.studentResidenceArea || undefined,
        tutorResidenceArea: inputs.tutorResidenceArea || undefined,
        studentParentCategory: inputs.studentParentCategory || undefined,
        artisanCSP: inputs.artisanCSP || undefined,
        intermediateCSP: inputs.intermediateCSP || undefined,
        employeeCSP: inputs.employeeCSP || undefined,
        workerCSP: inputs.workerCSP || undefined,
        inactiveCSP: inputs.inactiveCSP || undefined,
        professionalProject: inputs.professionalProject || undefined,
      };
      await dispatch(UserThunks.putMe(data));
      navigate(`/questionnaires${search}`);
    } catch (e: unknown) {
      const error = e as Error;
      toast.error(error.message);
    }
  };

  const isInvalid = (name: keyof Inputs): boolean => (formIsDirty || !!dirtyFields[name]) && !!errors[name];

  useEffect(() => {
    setBackPath('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.title}>
          <Trans t={t} i18nKey="user-form.title" />
        </div>

        <div className={styles.required}>
          <Trans t={t} i18nKey="user-form.required-fields" />
        </div>

        <form
          spellCheck={false}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* GENDER */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('gender')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.gender.label" /> *
                </FormField.Label>
                <FormField.Select
                  {...register('gender', {
                    required: t('user-form.fields.gender.errors.required') || '',
                  })}
                >
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.gender?.message}</FormElement.Error>
          </FormElement>

          {/* AGE */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('age')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.age.label" /> *
                </FormField.Label>
                <FormField.Input
                  type="number"
                  min={CONSTANTS.AGE_MIN}
                  max={CONSTANTS.AGE_MAX}
                  {...register('age', {
                    valueAsNumber: true,
                    required: t('user-form.fields.age.errors.required') || '',
                    min: {
                      value: CONSTANTS.AGE_MIN,
                      message: t('user-form.fields.age.errors.min', { min: CONSTANTS.AGE_MIN }),
                    },
                    max: {
                      value: CONSTANTS.AGE_MAX,
                      message: t('user-form.fields.age.errors.max', { max: CONSTANTS.AGE_MAX }),
                    },
                  })}
                />
              </FormField>
            </FormElement.Group>
            <FormElement.Legend isHidden={isInvalid('age')}>
              <Trans
                t={t}
                i18nKey="user-form.fields.age.legend"
                values={{ min: CONSTANTS.AGE_MIN, max: CONSTANTS.AGE_MAX }}
              />
            </FormElement.Legend>
            <FormElement.Error>{errors.age?.message}</FormElement.Error>
          </FormElement>

          {/* STUDIES LEVEL */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('studiesLevel')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.studies-level.label" /> *
                </FormField.Label>
                <FormField.Select
                  {...register('studiesLevel', {
                    required: t('user-form.fields.studies-level.errors.required') || '',
                  })}
                >
                  {studiesLevelOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.studiesLevel?.message}</FormElement.Error>
          </FormElement>

          {/* FAMILY SITUATION */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('familySituation')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.family-situation.label" /> *
                </FormField.Label>
                <FormField.Select
                  {...register('familySituation', {
                    required: t('user-form.fields.family-situation.errors.required') || '',
                  })}
                >
                  {situationOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.familySituation?.message}</FormElement.Error>
          </FormElement>

          {/* PROFESSIONAL PROJECT */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('professionalProject')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.professional-project.label" />
                </FormField.Label>
                <FormField.Textarea {...register('professionalProject')} />
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.professionalProject?.message}</FormElement.Error>
          </FormElement>

          {/* STUDENT RESIDENCE AREA */}
          <FormElement>
            <FormElement.Label className={styles.title}>
              <Trans t={t} i18nKey="user-form.fields.student-residence-area.title" />
            </FormElement.Label>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('studentResidenceArea')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.student-residence-area.label" />
                </FormField.Label>
                <FormField.Select {...register('studentResidenceArea')}>
                  <option value={undefined}> </option>
                  {studentResidenceAreaOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('studentParentCategory')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.student-parent-category.label" />
                </FormField.Label>
                <FormField.Select {...register('studentParentCategory')}>
                  <option value={undefined}> </option>
                  {studentParentCategoryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          {/* TUTOR RESIDENCE AREA */}
          <FormElement>
            <FormElement.Label className={styles.title}>
              <Trans t={t} i18nKey="user-form.fields.tutor-residence-area.title" />
            </FormElement.Label>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('tutorResidenceArea')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.tutor-residence-area.label" />
                </FormField.Label>
                <FormField.Select {...register('tutorResidenceArea')}>
                  <option value={undefined}> </option>
                  {tutorResidenceAreaOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          {/* ARTISAN CSP */}
          <FormElement>
            <FormElement.Label className={styles.title}>
              <Trans t={t} i18nKey="user-form.fields.artisan-csp.title" />
            </FormElement.Label>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('artisanCSP')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.artisan-csp.label" />
                </FormField.Label>
                <FormField.Select {...register('artisanCSP')}>
                  <option value={undefined}> </option>
                  {artisanCSPOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          {/* INTERMEDIATE CSP */}
          <FormElement>
            <FormElement.Label className={styles.title}>
              <Trans t={t} i18nKey="user-form.fields.intermediate-csp.title" />
            </FormElement.Label>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('intermediateCSP')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.intermediate-csp.label" />
                </FormField.Label>
                <FormField.Select {...register('intermediateCSP')}>
                  <option value={undefined}> </option>
                  {intermediateCSPOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          {/* EMPLOYEE CSP */}
          <FormElement>
            <FormElement.Label className={styles.title}>
              <Trans t={t} i18nKey="user-form.fields.employee-csp.title" />
            </FormElement.Label>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('employeeCSP')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.employee-csp.label" />
                </FormField.Label>
                <FormField.Select {...register('employeeCSP')}>
                  <option value={undefined}> </option>
                  {employeeCSPOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          {/* WORKER CSP */}
          <FormElement>
            <FormElement.Label className={styles.title}>
              <Trans t={t} i18nKey="user-form.fields.worker-csp.title" />
            </FormElement.Label>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('workerCSP')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.worker-csp.label" />
                </FormField.Label>
                <FormField.Select {...register('workerCSP')}>
                  <option value={undefined}> </option>
                  {workerCSPOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          {/* INACTIVE CSP */}
          <FormElement>
            <FormElement.Label className={styles.title}>
              <Trans t={t} i18nKey="user-form.fields.inactive-csp.title" />
            </FormElement.Label>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('inactiveCSP')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.inactive-csp.label" />
                </FormField.Label>
                <FormField.Select {...register('inactiveCSP')}>
                  <option value={undefined}> </option>
                  {inactiveCSPOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
          </FormElement>

          <Button
            as="button"
            variant="filled"
            disabled={isSubmitting}
            className={styles.button}
            onClick={() => setFormIsDirty(true)}
          >
            <Trans t={t} i18nKey="user-form.submit" />
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default UserQuestionnaire;
