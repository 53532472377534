import { isNil } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserSelectors } from 'core/store/user/user.selectors';

const QuestionnairesWrapper: React.FC = (): JSX.Element | null => {
  const { search } = useLocation();
  const user = useSelector(UserSelectors.data);

  if (!user) return null;

  const missingData = isNil(user.age) || isNil(user.gender) || isNil(user.studiesLevel) || isNil(user.familySituation);

  return missingData ? <Navigate to={`/user-questionnaire${search}`} /> : <Outlet />;
};

export default QuestionnairesWrapper;
