import Survey, { ForwardRef } from '@sbthuman/survey-front';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import Progress from 'components/Progress';
import Report from 'components/Report';
import ScreenLoader from 'components/ScreenLoader';
import useChrono from 'core/hooks/use-chrono';
import useCompleted from 'core/hooks/use-completed';
import useConfig from 'core/hooks/use-config';
import useHeader from 'core/hooks/use-header';
import useNext from 'core/hooks/use-next';
import useProgress from 'core/hooks/use-progress';
import useReport from 'core/hooks/use-report';
import { UserSelectors } from 'core/store/user/user.selectors';
import Container from '../../components/Container';
import styles from './Questionnaire.module.css';

type Params = {
  id: string;
};

type ContentProps = {
  id: number;
};

const Questionnaire: React.FC = () => {
  const { search } = useLocation();
  const { id } = useParams<Params>();

  return !id || Number.isNaN(+id) ? <Navigate to={`/questionnaires${search}`} /> : <Content key={id} id={+id} />;
};

const Content: React.FC<ContentProps> = ({ id }): JSX.Element | null => {
  // Contextual Hooks
  useCompleted(id);
  const next = useNext(id);
  const config = useConfig(id);
  const report = useReport(id);
  const chrono = useChrono(id);
  const progress = useProgress();

  // General Hooks
  const { search } = useLocation();
  const user = useSelector(UserSelectors.data);
  const { setBackPath, hideHeader } = useHeader();

  // Page states and ref
  const ref = useRef<ForwardRef>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [ended, setEnded] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);

  // Methods
  const onRefReady = useCallback(() => {
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStart = useCallback((): void => {
    chrono.start();
    setStarted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEnd = useCallback(async (): Promise<void> => {
    if (!ready || !ref.current || report.pending) return;
    if (!chrono.stopTime) chrono.stop();
    const time = (chrono.stopTime || Date.now()) - chrono.startTime;
    await report.getReport(ref.current.surveyEntries, time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chrono, report, ready]);

  useEffect(() => {
    setBackPath('/questionnaires');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (started) hideHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started]);

  // Init page on id change
  useEffect(() => {
    if (!id.toString()) return;
    setStarted(false);
    setEnded(false);
  }, [id]);

  // Show Toast on error
  useEffect(() => {
    if (config.error) toast.error(config.error);
    if (report.error) toast.error(report.error);
  }, [report.error, config.error]);

  // Update survey methods
  useEffect(() => {
    if (!ready || !ref.current) return;
    ref.current.settingsContext.patchSettingsState({ onStart, onEnd });
  }, [ready, onStart, onEnd]);

  // Reset entries if ended
  useEffect(() => {
    if (report.data) setEnded(true);
  }, [report.data]);

  if (config.pending) {
    return <ScreenLoader />;
  }

  if (!config.data || config.error) {
    return <Navigate to={`/questionnaires${search}`} />;
  }

  if (report.data && ended) {
    return (
      <Report
        isLast={next.isLast}
        onNext={next.onClick}
        message={report.data.text}
        badges={report.data.badges}
        audioUrl={report.data.textAudioUrl}
      />
    );
  }

  return (
    <div className={styles.root}>
      <Container>
        <Progress total={progress?.total} completed={progress?.completed} />
      </Container>
      <Container className={styles.surveyContainer}>
        <Survey ref={ref} id={user?.id || 'user'} persist config={config.data} onRefReady={onRefReady} />
      </Container>
    </div>
  );
};

export default Questionnaire;
