import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { Check } from 'components/Icons';
import QuestionnaireCard from 'components/QuestionnaireCard';
import useHeader from 'core/hooks/use-header';
import { QuestionnairesSelectors } from 'core/store/questionnaires/questionnaires.selectors';
import Container from '../../components/Container';
import styles from './Questionnaires.module.css';

const Questionnaires: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { setBackPath } = useHeader();
  const questionnaires = useSelector(QuestionnairesSelectors.all);

  useEffect(() => {
    setBackPath('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!questionnaires) return <Navigate to={`/${search}`} />;

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.title}>
          <Trans t={t} i18nKey="questionnaires.title">
            <span className={styles.primary} />
          </Trans>
        </div>

        <div className={styles.items}>
          {questionnaires.map((questionnaire) => (
            <div key={questionnaire.id} className={styles.item}>
              <div className={styles.bullet}>{questionnaire.isCompleted && <Check className={styles.icon} />}</div>
              <QuestionnaireCard {...questionnaire} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Questionnaires;
