import parse from 'html-react-parser';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import Button from 'components/Button';
import { ChevronRight, Like, Trophy } from 'components/Icons';
import Speech from 'components/Speech';
import { ReportBadge } from 'core/models/questionnaire.model';
import styles from './Report.module.css';
import Vector from './Vector';

type Props = {
  message?: string;
  isLast: boolean;
  onNext: () => void;
  badges: ReportBadge[];
  audioUrl: string;
};

const Report: React.FC<Props> = ({ badges, isLast, message, audioUrl, onNext }): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Vector className={styles.bgTop} />

      <div className={styles.top}>
        <div className={styles.like}>
          <Like />
        </div>
        <div className={styles.text}>
          <Trans t={t} i18nKey="report.saved" />
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.results}>
          <div className={styles.title}>
            <Trophy className={styles.icon} />
            <Trans t={t} i18nKey="report.results" />
          </div>
          {!!badges.length && (
            <div className={styles.badges}>
              {badges.map((badge) => (
                <Badge key={badge.title} {...badge} />
              ))}
            </div>
          )}
        </div>

        <div className={styles.message}>
          <Speech url={audioUrl} className={styles.speech} />
          {!!message && parse(message)}
        </div>

        <div className={styles.buttons}>
          {isLast && (
            <Button variant="filled" onClick={onNext}>
              <Trans t={t} i18nKey="report.finish" />
              <ChevronRight />
            </Button>
          )}
          {!isLast && (
            <Button variant="filled" onClick={onNext}>
              <Trans t={t} i18nKey="report.next" />
              <ChevronRight />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;
