import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Avatar } from 'assets/images/avatar.svg';
import Button from 'components/Button';
import Container from 'components/Container';
import { CalendarClock } from 'components/Icons';
import useHeader from 'core/hooks/use-header';
import styles from './Home.module.css';

const Home: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { setBackPath } = useHeader();

  useEffect(() => {
    setBackPath(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.title}>
          <Trans t={t} i18nKey="home.title" />
        </div>

        <div className={styles.duration}>
          <CalendarClock className={styles.icon} />
          <Trans t={t} i18nKey="home.duration" />
        </div>

        <Avatar className={styles.avatar} />

        <ol className={styles.instructions}>
          <li>
            <Trans t={t} i18nKey="home.instructions.0" />
          </li>
          <li>
            <Trans t={t} i18nKey="home.instructions.1" />
          </li>
          <li>
            <Trans t={t} i18nKey="home.instructions.2" />
          </li>
          <li>
            <Trans t={t} i18nKey="home.instructions.3" />
          </li>
        </ol>

        <Button as="a" to={`/questionnaires${search}`} variant="filled" className={styles.button}>
          <Trans t={t} i18nKey="home.button" />
        </Button>
      </Container>
    </div>
  );
};

export default Home;
