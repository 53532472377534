import React from 'react';
import { ReportBadge } from 'core/models/questionnaire.model';
import styles from './Badge.module.css';

type Props = ReportBadge;

const Badge: React.FC<Props> = ({ title, color, label, threshold, description }): JSX.Element | null => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      {threshold && <div className={styles.threshold}>{threshold}</div>}
    </div>
    {color && (
      <div className={styles.circle} data-color={color}>
        <div className={styles.label}>{label}</div>
      </div>
    )}
  </div>
);

export default Badge;
